.refund-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.refund-policy-heading-h1 {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

.refund-policy-heading-h2 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
    color: #333;
}

.refund-policy-text {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
    margin-bottom: 15px;
}

.refund-policy-list {
    list-style-type: disc;
    padding-left: 20px;
}

.refund-policy-list-item {
    font-size: 16px;
    color: #444;
    margin-bottom: 10px;
}

.refund-policy-list-item a {
    color: #007bff;
    text-decoration: none;
}

.refund-policy-list-item a:hover {
    text-decoration: underline;
}
